<mat-form-field [appearance]="props.appearance">
  <mat-label>{{props.label}}</mat-label>
  <button
    *ngIf="formControl.value"
    class="clearButton"
    (click)="clearSelection()">
    X
  </button>
  <mat-select [disableRipple]="true" [formControl]="formControl" [placeholder]="props.placeholder || ''">
    <ngx-mat-select-search
      [searching]="searching"
      [clearSearchInput]="false"
      [placeholderLabel]="props.placeholder || ''"
      [formControl]="formControlFilter"
      [noEntriesFoundLabel]="''"
    ></ngx-mat-select-search>

    <mat-spinner *ngIf="searching" diameter="10"></mat-spinner>
    <mat-option *ngFor="let item of filterOptions(formControlFilter.value);" [value]="item">
      {{ item.name || "" }}
      <br>
      {{ formatCnpjCpf(item.cpf_cnpj || this.field?.defaultValue) || "Sem CNPJ cadastrado" }}
    </mat-option>
  </mat-select>
</mat-form-field>
