import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { PlanningComponent } from './planning/planning.component';
import { SchedulingRoutingModule } from './scheduling-routing.module';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { CellTemplateComponent } from './cell-template/cell-template.component';
import { ScheduleListV1Component } from './schedule-list-v1/schedule-list-v1.component';
import { ShedulingDialogComponent } from './sheduling-dialog/sheduling-dialog.component';
import { ScheduleEditDialogV1Component } from './schedule-edit-dialog-v1/schedule-edit-dialog-v1.component';
import { FormlyInputCustomComponent } from '../shared/formly/formly-input-custom/formly-input-custom.component';
import { FormlyInputSearchComponent } from '../shared/formly/formly-input-search/formly-input-search.component';
import { FormlyInputSearchCnpjComponent } from '../shared/formly/formly-input-search-cnpj/formly-input-search-cnpj.component';
import { FormlyAutocompleteComponent } from '../shared/formly/formly-autocomplete/formly-autocomplete.component';
import { FormlyScheduleCodeComponent } from './../shared/formly/formly-schedule-code/formly-schedule-code.component';
import { ScheduleDinamicFormDialogComponent } from './schedule-dinamic-form-dialog/schedule-dinamic-form-dialog.component';
import { FormlyDatepickerRangeComponent } from '../shared/formly/formly-datepicker-range/formly-datepicker-range.component';
import { FormlyInputTagsOutlineComponent } from '../shared/formly/formly-input-tags-outline/formly-input-tags-outline.component';
import { ScheduleDinamicFormDialogV1Component } from './schedule-dinamic-form-dialog-v1/schedule-dinamic-form-dialog-v1.component';
import { PreScheduleListComponent } from './pre-schedule-list/pre-schedule-list.component';
import { PaginateSortTableComponent } from '../shared/paginate-sort-table/paginate-sort-table.component';
import { UploadListComponent } from '../shared/upload-list/upload-list.component';
import { FormlyInputSearchDriverComponent } from '../shared/formly/formly-input-search-driver/formly-input-search-driver.component';
import { FormlyInputSearchSupplierComponent } from '../shared/formly/formly-input-search-supplier/formly-input-search-supplier.component';
import { FormlyInputSearchCarrierComponent } from '../shared/formly/formly-input-search-carrier/formly-input-search-carrier.component';
import { DockScheduleComponent } from './dock-schedule/dock-schedule.component';


@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        UploadListComponent,
        PaginateSortTableComponent,
        SchedulingRoutingModule,
        ReactiveFormsModule,
        FormlyModule.forRoot({
            validationMessages: [
                { name: 'required', message: 'Este campo é obrigatório' },
            ],
            types: [
              {
                name: "formly-autocomplete",
                component: FormlyAutocompleteComponent
              },
              {
                name: "formly-datepicker-range",
                component:FormlyDatepickerRangeComponent
              },
              {
                name: 'formly-input-search',
                component: FormlyInputSearchComponent
              },
              {
                name: 'formly-input-search-cnpj',
                component: FormlyInputSearchCnpjComponent
              },
              {
                name: 'formly-input-search-driver',
                component: FormlyInputSearchDriverComponent
              },
              {
                name: 'formly-input-search-supplier',
                component: FormlyInputSearchSupplierComponent
              },
              {
                name: 'formly-input-search-carrier',
                component: FormlyInputSearchCarrierComponent
              },
              {
                  name: 'formly-schedule-code',
                  component: FormlyScheduleCodeComponent,
              },
              {
                  name: 'formly-input-tags-outline',
                  component: FormlyInputTagsOutlineComponent,
              },
              {
                name: 'formly-input-custom',
                component: FormlyInputCustomComponent
              }
            ],
        }),
        FormlyMaterialModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatStepperModule
    ],
    declarations: [
        ShedulingDialogComponent,
        ScheduleDinamicFormDialogComponent,
        ScheduleListComponent,
        CellTemplateComponent,
        ScheduleDinamicFormDialogV1Component,
        ScheduleEditDialogV1Component,
        ScheduleListV1Component,
        PlanningComponent,
        PreScheduleListComponent,
        DockScheduleComponent
    ]
})
export class SchedulingModule {}
