<div class="page-container">
  <div class="lateral-container">
    <div class="tkg-grid">
      <div class="schedule-grid">
        <div id="schedule-0">
          <div class="lateral-itens">
            <p class="data">{{ selectedDate | date: "mediumDate" }}</p>
            <mat-calendar
              [selected]="selectedDate"
              [startAt]="startAt"
              [maxDate]="maxDate"
              (selectedChange)="onSelect($event)"
            ></mat-calendar>
          </div>
        </div>

        <div id="schedule-1">
          <div class="tkg-spinner-container" *ngIf="showLoader">
            <div class="spinner-and-text">
              <mat-progress-spinner
                class="tkg-spinner"
                color="#1fd91d"
                mode="indeterminate"
                value="100"
              ></mat-progress-spinner>
              <div class="load-txt">
                {{ "Business_model.loading_schedule" | translate: { Default: "Carregando agendamentos..." } }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div class="main-container">
      <table class="dock-schedule-table">
        <thead>
          <tr>
            <th class="sticky-column">Docas</th>
            <th *ngFor="let slot of slotTimeList">{{ slot.slot }}</th>
          </tr>
        </thead>
        <tbody *ngIf="slotTimeList && slotTimeList.length">
          <tr *ngFor="let dock of scheduleDocks">
            <td class="sticky-column">{{ dock.dock_name }}</td>
            <ng-container *ngFor="let slot of slotTimeList">
              <ng-container *ngIf="decreaseSkip()">
                <td 
                  [colSpan]="renderCell(dock.dock_id, slot.slot)"
                  (click)="handleSlotClick(dock.dock_id, slot.slot)"
                  [ngStyle]="{'background-color': showInfos(dock.dock_id, slot.slot).background_color, 'color': showInfos(dock.dock_id, slot.slot).color}"
                  > 
                  {{showInfos(dock.dock_id, slot.slot).text}}              
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </tbody>
        
      </table>
    </div>
</div>
