import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { format } from "date-fns";
import { AgendamentoService } from 'src/app/multiagendamento/shared/agendamento.service';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { ScheduleEditDialogV1Component } from '../schedule-edit-dialog-v1/schedule-edit-dialog-v1.component';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleDinamicFormDialogV1Component } from '../schedule-dinamic-form-dialog-v1/schedule-dinamic-form-dialog-v1.component';

@Component({
  selector: 'app-dock-schedule',
  templateUrl: './dock-schedule.component.html',
  styleUrls: ['./dock-schedule.component.scss']
})
export class DockScheduleComponent implements OnInit {

  slotTimeList: any = [];
  scheduleDocks:any[] = []
  selectedDate = new Date();
  startAt = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  selectedDay!: string;
  open: BehaviorSubject<boolean> = new BehaviorSubject(false);
  form = this.formBuilder.group({
    day: [format(new Date(), "yyyy-MM-dd"), [Validators.required]],
    time: null,
    search: null,
    department_id: [],
    origin: [],
    operation_id: null,
    suboperation_id: null,
    status_id: [],
    offset: this.getTimeZone(),
  });
  status: any = [];
  account: any = {};
  departments: any = [];
  operations: any = [];
  suboperations: any = [];
  origins: any = [];
  showLoader = false;
  skips: number = 0
  // filterList: any[] = [];
  // visibleFilters: any = [];
  // allData: any;
  // getData: string = "";
  // showOperation = false;
  // showSubOperation = false;
  // scheduleMenu: any = [];
  // scheduleColumns: any = [];
  // allSuboperations: any = [];

  ngOnInit() {
    //this.getDockSchedules();
    this.getTimeSlot();

    this.skips = 0;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private agendamentoService: AgendamentoService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.onSelect(this.selectedDate);
  }

  getTimeSlot() {
    const options = {
      department_id: this.form.value.department_id,
      operation_id: this.form.value.operation_id,
      suboperation_id: this.form.value.suboperation_id,
      day: this.form.value.day,
      offset: this.form.value.offset,
    };
    this.agendamentoService.dockSlotTime(options).subscribe({
      next: (r: any) => {
        this.slotTimeList = r;
        console.log(this.slotTimeList);
      },
      error: (error) => {
        console.log("error", error);
      },
    });
  }

  getDockSchedules() {
    this.scheduleDocks = [];
    const filters = { ...this.form.value };
    const options = {
      ...filters,
      day: this.form.value.day,
      offset: this.form.value.offset,
    };
    if (this.form.value.search != null) {
      options.search = this.form.value.search;
    }
    this.agendamentoService.getDockSchedule(options).subscribe({
      next: (r: any) => {
        this.scheduleDocks = r;
        console.log(this.scheduleDocks)
        this.showLoader = false;
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  newDockSchedule(dockId: number, slot: string) {
    this.open = new BehaviorSubject(false);

    const dialogRef = this.dialog.open(ScheduleDinamicFormDialogV1Component, {
      width: "900px",
      data: {
        dialogAction: this.translate.instant("Business_model.new_schedule", {
          Default: "Novo Agendamento",
        }),
        slot: null,
        schedule: {
          id: null,
          selected_day: this.selectedDay,
          status: 1,
          schedule_type: "schedule",
          slot_type: 'dock',
          dock_id: dockId,
          time_slot: slot,
        },
        //Criar e editar utilizam o mesmo modal, então para a criação 'isEditingAllowed' é marcado como true para que todos os campos estejam habilitados
        isEditingAllowed: true,
      },
    });

    dialogRef.afterClosed().subscribe((statusResult?: any) => {
      //Se status for new_code_generated, abrir modal informando o usuário
      //Que o agendamento foi criado com um código diferente do informado
      if (statusResult && statusResult.status == "new_code_generated") {
        this.dialog
          .open(DialogAlertComponent, {
            data: {
              title: this.translate.instant("Business_model.created_schedule", {
                Default: "Agendamento criado",
              }),
              message:
                statusResult.message ||
                this.translate.instant("Messages.created_schedule", {
                  Default: "Agendamento criado, código alterado.",
                }),
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getDockSchedules();
          });
      } else {
        this.getDockSchedules();
      }
    });
  }

  showScheduleInfo(schedule: any) {
    const status = schedule.status;
    this.open = new BehaviorSubject(false);
    const dialogRef = this.dialog.open(ScheduleEditDialogV1Component, {
      width: "600px",
      panelClass: "schedule-dialog-edit-v1",
      autoFocus: false,
      data: {
        dialogAction: this.translate.instant("Business_model.edit_status", {
          Default: "Editar Status",
        }),
        slot: {},
        schedule: schedule[0],
      },
    });

    dialogRef.afterClosed().subscribe((statusResult?: any) => {
      if (statusResult && statusResult.action == "edit") {
        this.dialog
          .open(ScheduleDinamicFormDialogV1Component, {
            width: "900px",
            data: {
              dialogAction: statusResult.isEditingAllowed
                ? this.translate.instant("Business_model.edit_schedule", {
                    Default: "Editar Agendamento",
                  })
                : this.translate.instant("Business_model.view_schedule", {
                    Default: "Visualizar Agendamento",
                  }),
              slot: {},
              schedule: statusResult.schedule,
              //Valor que define se é permitido editar um agendamento que não fez o checkin ainda
              isEditingAllowed: statusResult.isEditingAllowed,
            },
          })
          .afterClosed()
          .subscribe((editResult?: any) => {
            if (editResult?.message || status != schedule.status) {
              //Se status for new_code_generated, abrir modal informando o usuário
              //Que o agendamento foi editado com um código diferente do informado
              if (
                editResult.status &&
                editResult.status == "new_code_generated"
              ) {
                this.dialog
                  .open(DialogAlertComponent, {
                    data: {
                      title: this.translate.instant(
                        "Business_model.editing_schedule",
                        { Default: "Agendamento edição" },
                      ),
                      message:
                        editResult.message ||
                        this.translate.instant("Messages.edited_schedule", {
                          Default: "Agendamento editado, código alterado",
                        }),
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.getDockSchedules();
                  });
              } else {
                this.dialog
                  .open(DialogAlertComponent, {
                    data: {
                      title: this.translate.instant(
                        "Business_model.editing_schedule",
                        { Default: "Agendamento edição" },
                      ),
                      message:
                        editResult?.message ||
                        this.translate.instant("Messages.edit_status", {
                          Default: "Status alterado com sucesso!",
                        }),
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.getDockSchedules();
                  });
              }
            }
          });
      } else if (statusResult && statusResult.action == "refresh") {
        this.getDockSchedules();
      }
    });
  }

  onSelect(event: any) {
    this.selectedDate = event;
    this.selectedDay = `${format(new Date(event), "yyyy-MM-dd")}T00:00:00`;
    this.form.patchValue({ day: format(new Date(event), "yyyy-MM-dd") });
    this.getDockSchedules()
  }

  getTimeZone() {
    let offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2)
    );
  }

  showInfos(dockId: number, slot: string) {
    const line = this.scheduleDocks.filter((l: any) => dockId === l.dock_id);
    const schedule = line[0].events.filter((s: any) => slot === s.time_slot);
    if (schedule.length > 0) {
      if(schedule[0].carrier_name != null) {
        return {
          text: `${schedule[0].carrier_name} - ${schedule[0].driver_name}`,
          background_color: schedule[0].status_color,
          color: 'white'
        };
      } else {
        return {
          text: 'Horário Agendado',
          background_color: schedule[0].status_color,
          color: '#515151'
        }
      }     
    }
  
    return {text: '', background_color: ''};
  }

  handleSlotClick(id: number, slotTime: string,): void {
    const line = this.scheduleDocks.filter((l: any) => id === l.dock_id);
    const schedule = line[0].events.filter((s: any) => slotTime === s.time_slot);
    if(schedule.length > 0) {
      if(schedule[0].disable == false) {
        this.showScheduleInfo(schedule);
      }
    } else {
      this.newDockSchedule(id, slotTime);
    }
  }
 
  renderCell(id: number, slotTime: string) {
    const line = this.scheduleDocks.filter((l: any) => id === l.dock_id);
    const schedule = line[0].events.filter((s: any) => slotTime === s.time_slot);

    if(schedule.length > 0) {
      let duration = schedule[0].duration / 15;

      this.skips = duration;
      return duration

    } else {
      return 1
    }
  }

  decreaseSkip() {
    this.skips = this.skips - 1;

    if(this.skips > 0) {
      return false;
    }
    return true
  }

}
